import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"

export default function Essay({ params }) {

  const [essay, setEssay] = useState({});
  const { id } = params;

  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = () => {
    fetch(`https://admin.curranindex.org/items/essays/${id}`)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then(data => {
        setEssay(data.data)
      })
      .catch(error => {
        console.error("Error fetching data: " + error);
      })
  };

  const getMonthString = (yearInt, monthInt) => {
    const date = new Date(yearInt, monthInt -1);
    return date.toLocaleString('default', { month: 'long' }) || "";
  }

  const titleStyle = {
    fontSize: "1.25em",
    marginBottom: "10px"
  }

  const subtitleStyle = {
    fontSize: "1em",
    marginBottom: "10px"
  }

  return (
    <Layout>
      <div>
        <div align="center">&nbsp;</div>
        <div style={titleStyle}> {essay.title} </div>
        <div style={essay.title ? subtitleStyle : titleStyle}> {getMonthString(essay.pub_year, essay.pub_month)}, {essay.pub_year} </div>
        <div
          dangerouslySetInnerHTML={{__html: essay.contents}}>
        </div>
      </div>
    </Layout>
  )
}
